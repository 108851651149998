.frutselke-2 {
    transform: rotate(180deg) scale(0.6);
    float: right;
}

.tekst-wrapper {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 200;
    font-size: 16px;
    gap: 5px;
}

.tekst-wrapper-margin-top {
    margin-top: 80px;
}

.tekst-font-size {
    font-size: 16px;
}

.tekst-wrapper-padding {
    padding-inline: 20vw;
}

@media only screen and (max-width: 1300px) {
    .tekst-wrapper-padding {
        padding-inline: 15vw;
    }
}

@media only screen and (max-width: 1050px) {
    .tekst-font-size {
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {
    #frutselke-2 {
        display: none;
    }

    .tekst-wrapper-padding {
        padding-inline: 10vw;
    }

    .tekst-wrapper-margin-top {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .tekst-wrapper-margin-top {
        margin-top: 20px;
    }
}



