
.navbar-placeholder {
    height: 76px;
}

.navbar-box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-inline: 4vw;
    justify-content: space-between;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    background: #ffffff;
    width: 100%;
}

.logo-wrapper {
    width: 270px;
}

.right-nav-element {
    width: 270px;
}

.navbar-elements {
    gap: 3vw;
}

.navbar-element-desktop {
    color: #000000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
}

.navbar-element-mobile {
    color: #AA9767;
    text-decoration: none;
    font-size: 25px;
    font-weight: 700;
}

.sticky {
    z-index: 9000;
    position: fixed;
    top: 0;
    box-shadow: 0 0 13px rgb(0 0 0 / 15%);
}

.header-logo-text {
    font-family: 'Rubik', 'Inter', Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #AA9767;
}

@media only screen and (min-width: 1150px) {
    #logo-text-wrapper {
        justify-content: flex-end;
    }

    .open-mobile-nav {
        display: none;
    }

    .primary-navigation-mobile {
        display: none;
    }

    .plan-visit-button {
        color: #ffffff;
        outline: none;
        background: #AA9767;
        padding: 12px 20px;
        font-size: 14px;
        border: none;
        border-radius: 10px;
        font-family: 'Inter', Arial, Helvetica, sans-serif;
        font-weight: 400;
        cursor: pointer;
        float: right;
    }
}

@media only screen and (max-width: 1150px) {
    #logo-text-wrapper {
        justify-content: center;
    }
    
    .navbar-placeholder {
        height: 66px;
    }

    .primary-navigation-desktop {
        display: none;
    }

    .open-mobile-nav {
        display: block;
        outline: none;
        border: none;
        background: transparent;
    }

    .openMobileNav {
        transform: translate(0%) !important;
    }

    .primary-navigation-mobile {
        display: flex;
        justify-content: center;
        position: fixed;
        inset: 0;
        flex-direction: column;
        z-index: 9998;
        gap: 20px;
        transform: translate(-100%);
        -webkit-transition: transform 350ms ease-out;
        -moz-transition: transform 350ms ease-out;
        -o-transition: transform 350ms ease-out;
        transition: transform 350ms ease-out;
    }

    .primary-navigation-mobile-background {
        background: rgba(255, 255, 255, 0.8);
    }

    @supports (backdrop-filter: blur(1rem)) or (-webkit-backdrop-filter: blur(1rem)) {
        .primary-navigation-mobile-background {
            background: rgba(255, 255, 255, 0.65);
            -webkit-backdrop-filter: blur(1rem);
            backdrop-filter: blur(1rem);
        }
    }

    .right-nav-element {
        width: auto;
    }

    .plan-visit-button {
        display: none;
    }

    .close-mobile-nav-button {
        right: 20px;
        top: 20px;
        outline: none;
        border: none;
        background: transparent;
    }

    .mobile-nav-elements-gap {
        gap: 10vh;
    }
}


.home-wrapper {
    width: 100%;
    height: calc(100vh - 76px);
}

.price-address-house-wrapper {
    background: #222A27;
    color: #ffffff;
}

.price-address-house-wrapper-padding {
    padding: 20px 50px 20px 30px;
}

.price {
    font-family: 'IBM Plex Sans','Inter', Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.price-font-size {
    font-size: 40px;
}

.address {
    font-size: 15px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.address-margin-top {
    margin-top: 5px;
}

.address-font-size {
    font-size: 15px;
}

.plan-visit-button-wrapper-home {
    margin-top: 20px;
    background: #181E1B;
    font-size: 14px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #ffffff;
    padding-block: 10px;
    border: none;
    outline: none;
    cursor: pointer;
}

.characteristic-house-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    background-color: black;
    opacity: 0.87;
}

.characteristic-house-width {
    width: 190px;
}

.characteristic-house-text {
    color: #ffffff;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.characteristic-house-text-font-size {
    font-size: 11px;
}

.bedrooms {
    border-bottom: 1px solid #2D2D2D;
    border-right: 1px solid #2D2D2D;
}

.surface {
    border-bottom: 1px solid #2D2D2D;
    border-left: 1px solid #2D2D2D;
}

.toilets {
    border-top: 1px solid #2D2D2D;
    border-right: 1px solid #2D2D2D;
}

.bathroom {
    border-top: 1px solid #2D2D2D;
    border-left: 1px solid #2D2D2D;
}

.header-image-wrapper {
    height: 90%;
    margin-inline: 10vw;
    background-image: url("../assets/background-home.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.header-image-wrapper::after {
    content: '';
    height: 45%;
    width: 15px;
    position: absolute;
    background-color: #AA9767;
    top: 22.5%;
}

.mail-wrapper-desktop {
    z-index: 1;
    top: 0;
    left: 0;
    width: 10vw;
    height: 90%;
}

.email-tekst {
    transform: rotate(-90deg);
    font-family: 'IBM Plex Sans','Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #000000;
    text-decoration: none;
}

.email-icon {
    transform: rotate(90deg);
    padding-left: 5px;
}

.frutselke-wrapper {
    bottom: -40px;
    left: 20vw;
}

.frutselke {
    height: 155px;
}

.characteristic-icon {
    width: 35px;
    aspect-ratio: 1;
}

@media only screen and (max-width: 1300px) {
    .frutselke-wrapper {
        bottom: -40px;
        left: 15vw;
    }
}

@media only screen and (max-width: 1150px) {
    .home-wrapper {
        width: 100%;
        height: calc(100vh - 66px);
    }
}

@media only screen and (max-width: 900px) {
    .frutselke-wrapper {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    .basic-info-house-wrapper {
        z-index: 1;
        bottom: 20px;
        right: 7.5vw;
    }
}

@media only screen and (max-width: 700px) {
    .address-margin-top {
        margin-top: 10px;
    }

    .price-address-house-wrapper-padding {
        padding: 25px;
    }

    .characteristic-house-text-font-size {
        font-size: 13px;
    }
    
    .characteristic-icon {
        width: 50px;
        aspect-ratio: 1;
    }

    .address-font-size {
        font-size: 14px;
    }

    .price-font-size {
        font-size: 35px;
    }

    .mail-wrapper-desktop {
        display: none;
    }

    .header-image-wrapper {
        height: 40%;
        margin-inline: 0;
        background-image: url("../assets/background-home.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .header-image-wrapper::after {
        content: '';
        height: 20%;
        width: 10px;
        position: absolute;
        background-color: #AA9767;
        top: 10%;
    }

    .info-house-mobile {
        flex-direction: column;
        max-height: 60vh;
    }
    
    .basic-info-house-wrapper {
        z-index: 1;
        bottom: 10%;
        left: 12.5%;
        width: 75vw;
    }

    .characteristic-house-width {
        width: 100%;
        aspect-ratio: 1;
        max-height: 30vh;
    }
}

@media only screen and (max-width: 360px) {
    .address-font-size {
        font-size: 13px;
    }

    .price-font-size {
        font-size: 30px;
    }

}
