.footer-wrapper {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 10px;
}

.footer-text {
    font-family: 'Poppins', 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 15px;
}

@media only screen and (max-width: 900px) {
    .footer-wrapper {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
