.eye:hover {
    transform: scale(1.1);
}

#eye-1 {
    left: 15%;
    top: 15%;
}

#eye-2 {
    right: 15%;
    top: 45%;
    transform: rotate(45deg);
}

#eye-3 {
    right: 15%;
    top: 12%;
    transform: rotate(45deg);
}

#eye-4 {
    right: 17%;
    top: 48%;
    transform: rotate(180deg);
}

#eye-5 {
    right: 10%;
    top: 76%;
    transform: rotate(120deg);
}

#eye-6 {
    right: 43%;
    top: 78%;
    transform: rotate(-90deg);
}

#eye-7 {
    right: 10%;
    top: 15%;
    transform: rotate(-120deg);
}

#eye-8 {
    left: 10%;
    top: 40%;
    transform: rotate(70deg);
}

#eye-9 {
    right: 12%;
    top: 59%;
    transform: rotate(40deg);
}

#eye-10 {
    right: 25%;
    top: 70%;
    transform: rotate(90deg);
}

#eye-11 {
    left: 30%;
    top: 73%;
    transform: rotate(80deg);
}

.eye-width {
    width: 80px;
    aspect-ratio: 1;
}

@media only screen and (min-width: 1250px) {
    .eye-width {
        width: 80px;
        aspect-ratio: 1;
    }
}

@media only screen and (max-width: 1250px) {
    .eye-width {
        width: 70px;
        aspect-ratio: 1;
    }
}

@media only screen and (max-width: 1050px) {
    .eye-width {
        width: 65px;
        aspect-ratio: 1;
    }

    #eye-2 {
        right: 15%;
        top: 42%;
        transform: rotate(45deg);
    }

    #eye-5 {
        right: 10%;
        top: 74%;
        transform: rotate(120deg);
    }

    #eye-6 {
        right: 43%;
        top: 74%;
        transform: rotate(-90deg);
    }

    #eye-7 {
        right: 10%;
        top: 18%;
        transform: rotate(-120deg);
    }

    #eye-8 {
        left: 10%;
        top: 38%;
        transform: rotate(70deg);
    }

    #eye-10 {
        right: 23%;
        top: 68%;
        transform: rotate(90deg);
    }
}

@media only screen and (max-width: 950px) {
    .eye-width {
        width: 60px;
        aspect-ratio: 1;
    }
}

@media only screen and (max-width: 900px) {
    .eye-width {
        width: 70px;
        aspect-ratio: 1;
    }
}


