html {
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

ul {
  list-style: none;
}

li {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.flex-grow {
  flex-grow: 1;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.background-image {
  background-image: url("../assets/kompas.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: 100vh;
  left: -50vw;
  width: 210vw;
  transform: rotate(165deg);
  aspect-ratio: 807/819;
  opacity: 0.03;
  z-index: -1;
}

.background-image-position-and-width {
  top: 100vh;
  left: -50vw;
  width: 210vw;
  transform: rotate(165deg);
}

.cursor-pointer {
  cursor: pointer;
}

.description-paragraph {
  line-height: 1.4;
}

@media only screen and (max-width: 1050px) {
  .background-image-position-and-width {
    top: 120vh;
    left: -60vw;
    width: 230vw;
    transform: rotate(165deg);
  }
}

@media only screen and (max-width: 900px) {
  .background-image-position-and-width {
    top: 120vh;
    left: -110vw;
    width: 340vw;
    transform: rotate(165deg);
  }
}

@media only screen and (max-width: 600px) {
  .background-image-position-and-width {
    top: 120vh;
    left: -230vw;
    width: 600vw;
    transform: rotate(171deg);
  }
}

@media only screen and (max-width: 500px) {
  .background-image-position-and-width {
    top: 150vh;
    left: -230vw;
    width: 600vw;
    transform: rotate(171deg);
  }
}


