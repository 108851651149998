.cookie-popup-wrapper {
    position: fixed;
    inset: 0;
    transform: translate(-100%);
    z-index: -2;
    display: flex;
    flex-direction: column;
}

.cookie-popup-position {
    justify-content: center;
}

.cookie-image {
    width: 50px;
}

.show-cookie-popup {
    transform: translate(0%) !important;
    z-index: 9995 !important;
}

.cookie-message-wrapper {
    background: #222A27;
    padding: 20px;
    justify-content: space-between;
}

.cookie-message-wrapper-width {
    width: 500px;
}

.cookie-message {
    margin-top: 10px;
    text-align: center;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #ffffff; 
}

.cookie-message-width {
    width: 75%
}

.cookie-message-font-size {
    font-size: 15px;
}

@supports (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px)) {
    .cookie-popup-wrapper-background {
        background: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
}

.cookie-popup-wrapper-background {
    background: rgba(255, 255, 255, 0.8);
}

.accept-cookies-button {
    background: #181E1B;
    outline: none;
    border: none;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: #ffffff;
    padding-block: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 50%;
}

.reject-cookies-button {
    outline: none;
    border: none;
    background: transparent;
    color: #ffffff;
    text-decoration: underline;
}

@media only screen and (max-width: 550px) {
    .cookie-popup-position {
        justify-content: flex-end;
    }

    .cookie-message-wrapper-width {
        width: 100%;
    }

    .cookie-message-font-size {
        font-size: 14px;
    }

    .cookie-image {
        width: 40px;
    }

    .cookie-message-width {
        width: 80%
    }
}

@media only screen and (max-width: 420px) {
    .cookie-message-font-size {
        font-size: 13px;
    }
}