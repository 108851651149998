
.garden-tekst-wrapper {
    margin-top: 10vh;
    margin-bottom: 40px;
}

.garden-tekst {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
}

.garden-tekst-font-size {
    font-size: 35px;
}


@media only screen and (min-width: 1700px) {
    .garden-image-width {
        width: 350px;
    }
}

@media only screen and (max-width: 1800px) {
    .garden-image-width {
        width: 300px;
    }
}

@media only screen and (max-width: 900px) {
    .garden-tekst-wrapper {
        margin-top: 10vh;
        margin-bottom: 6vh;
    }
}

@media only screen and (max-width: 600px) {
    .garden-tekst-font-size {
        font-size: 30px;
    }

    .garden-tekst-wrapper {
        margin-top: 10vh;
        margin-bottom: 6.5vh;
    }
}

@media only screen and (max-width: 400px) {
    .garden-image-width {
        width: 270px;
    }

    .garden-tekst-font-size {
        font-size: 25px;
    }

    .garden-tekst-wrapper {
        margin-top: 10vh;
        margin-bottom: 7vh;
    }
}
