.overlay-hidden {
    position: fixed;
    inset: 0;
    transform: translate(-100%);
    z-index: -2;
}

@supports (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px)) {
    .overlay-background {
        background: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }
}

.overlay-background {
    background: rgba(255, 255, 255, 0.8);
}

.show-overlay {
    transform: translate(0%) !important;
    z-index: 9991;
}

.views-wrapper {
    height: 100%;
}

.close-button-hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: -2;
}

.close-button-wrapper {
    position: fixed;
    left: auto;
    z-index: 9992;
}

.close-button-wrapper-position {
    right: 50px;
    top: 50px;
}

.close-button {
    width: 50px;
    aspect-ratio: 1;
}

.slider-wrapper {
    width: 60% !important;
}


:root {
    --swiper-theme-color: #AA9767;
}

.swiper-button-next {
    background-image: url('../assets/arrow-203.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100px;
    height: 100px;
    top: 50%;
    margin-top: -50px;
}
  
.swiper-button-next::after {
    display: none;
}

.swiper-slide { display: block; }

.swiper-wrapper { display: flex; }

.swiper-button-prev {
    background-image: url('../assets/arrow-204.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100px;
    height: 100px;
    top: 50%;
    margin-top: -50px;
}

.myswiper {
    width: 100%;
}
  
.swiper-button-prev::after {
    display: none;
}

.slide-inactive {
    transition: opacity 900ms ease-in-out;
    opacity: 0;
}

.swiper-slide-active {
    opacity: 1 !important;
}

@media only screen and (min-width: 1300px) {
    .slider-wrapper {
        width: 60% !important;
    }
}

@media only screen and (max-width: 1300px) {
    .slider-wrapper {
        width: 70% !important;
    }
}

@media only screen and (max-width: 1050px) {
    .slider-wrapper {
        width: 80% !important;
    }
}

@media only screen and (max-width: 900px) {
    .slider-wrapper {
        width: 90% !important;
    }

    .swiper-button-next {
        width: 60px;
        height: 60px;
        top: auto;
        margin-top: 0;
        bottom: 5vh;
    }

    .swiper-button-prev {
        width: 60px;
        height: 60px;
        top: auto;
        margin-top: 0;
        bottom: 5vh;
    }

    .close-button-wrapper-position {
        right: 25px;
        top: 25px;
    }
}

@media only screen and (max-width: 900px) {
    .slider-wrapper {
        width: 95% !important;
    }
}

@media only screen and (max-width: 450px) {
    .slider-wrapper {
        width: 100% !important;
    }
}


