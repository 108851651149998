.images-wrapper {
    aspect-ratio: 1920/1080;
}

.landscape-photo {
    transition: all 600ms ease-in-out;
    aspect-ratio: 1920/1080;
    top: 0;
    left: 0;
    right: 0;
    right: 0;
}

.images-width {
    width: 500px;
}

.visualization {
    z-index: -1;
}

.showVisualization {
    opacity: 0;
}

.view-place-tekst-wrapper {
    margin-top: 10vh;
    margin-bottom: 30px;
}

.view-place-tekst {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
}

.view-place-tekst-font-size {
    font-size: 35px;
}

.show-visualization-button {
    outline: none;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px 20px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    background: transparent;
    z-index: 2;
}

.show-visualization-button-font-size {
    font-size: 16px;
}

.show-visualization-button-desktop {
    display: flex;
}

.show-visualization-button-mobile {
    display: none;
}

@media only screen and (min-width: 1700px) {
    .images-width {
        width: 600px;
    }
}

@media only screen and (max-width: 1800px) {
    .images-width {
        width: 500px;
    }
}

@media only screen and (max-width: 900px) {
    .view-place-tekst-wrapper {
        margin-top: 10vh;
        margin-bottom: 6vh;
    }

    .show-visualization-button-desktop {
        display: none;
    }

    .show-visualization-button-mobile {
        display: flex;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .images-width {
        width: 400px;
    }

    .view-place-tekst-font-size {
        font-size: 30px;
    }

    .view-place-tekst-wrapper {
        margin-top: 15vh;
        margin-bottom: 6.5vh;
    }

    .show-visualization-button-font-size {
        font-size: 15px;
    }
}

@media only screen and (max-width: 450px) {
    .images-width {
        width: 340px;
    }
}

@media only screen and (max-width: 400px) {
    .view-place-tekst-font-size {
        font-size: 25px;
    }

    .view-place-tekst-wrapper {
        margin-top: 15vh;
        margin-bottom: 7vh;
    }
}

@media only screen and (max-width: 360px) {
    .images-width {
        width: 300px;
    }
}



