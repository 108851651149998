.neighbourhood-wrapper {
    margin-top: 100px;
    gap: 40px;
}

.neighbourhood-wrapper-padding-inline {
    padding-inline: 20vw;
}

.map {
    width: 60vw;
    aspect-ratio: 2/1;
}

.houseMarker {
    background-image: url('../assets/customMarker.svg');
    background-size: cover;
    width: 50px;
    aspect-ratio: 1;
}

.shopMarker {
    background-image: url('../assets/ph_shopping-cart-light.svg');
    background-size: cover;
    width: 40px;
    aspect-ratio: 1;
}

.schoolMarker {
    background-image: url('../assets/emojione-monotone_school.svg');
    background-size: cover;
    width: 40px;
    aspect-ratio: 1;
}

.parkingMarker {
    background-image: url('../assets/ri_parking-line.svg');
    background-size: cover;
    width: 40px;
    aspect-ratio: 1;
}


.neighbourhood-text {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
}

.parking-options-site-link {
    font-weight: 400;
}

@media only screen and (max-width: 1300px) {
    .map {
        width: 70vw;
        aspect-ratio: 2/1;
    }

    .neighbourhood-wrapper-padding-inline {
        padding-inline: 15vw;
    }
}

@media only screen and (max-width: 1050px) {
    .neighbourhood-text {
        font-family: 'Inter', Arial, Helvetica, sans-serif;
        font-weight: 300;
        font-size: 15px;
    }

    .neighbourhood-wrapper {
        margin-top: 70px;
        gap: 40px;
    }
}

@media only screen and (max-width: 950px) {
    .neighbourhood-wrapper {
        margin-top: 70px;
        gap: 40px;
    }
}

@media only screen and (max-width: 900px) {
    .neighbourhood-wrapper-padding-inline {
        padding-inline: 10vw;
    }

    .map {
        width: 80vw;
        aspect-ratio: 2/1;
    }
}

