.contact-wrapper {
    margin-top: 100px;
    gap: 35px;
}

.contact-wrapper-padding-inline {
    padding-inline: 20vw;
}

.contact-title {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 25px;
}

.head-shot {
    width: 300px;
    aspect-ratio: 961/1440;
}

.formLabel {
    font-family: 'poppins', 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
}

.form-element-wrapper {
    flex: 1;
    border-bottom: 1px solid #222A27;
    padding-bottom: 3px;
    padding-left: 2px;
    gap: 6px;
}

.form-input {
    border: none;
    outline: none;
    background: transparent;
}

.form-gap {
    gap: 30px;
}

.contact-gap-desktop {
    gap: 3vw;
}

.form-elements-gap {
    gap: 3vw;
}

.optional {
    font-size: 9px;
}

.contact-info-text {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 13px;
    color: #000000;
}

.contact-form {
    margin-top: 30px;
}

input[type='radio'] {
    position: absolute;
    left: -9999em;
}

.check-box {
    height: 25px;
    border: 1px solid #000000;
    aspect-ratio: 1;
    background: transparent;
}

.input-message {
    outline: none;
    border: 1px solid #000000;
    height: 100px;
    padding: 5px;
}

textarea {
    outline: none;
    border: 1px solid #000000;
    height: 100px !important;
    width: 100% !important;
    padding: 5px;
    resize: none;
    font-family: 'Poppins', 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
}

.submit-button {
    padding: 10px 50px;
    background: #222A27;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
    border: none;
}

.checkMark {
    visibility: hidden;
}

a {
    text-decoration: none;
    color: #000000;
}

.contact-info-wrapper {
    gap: 50px;
}

.info-options-wrapper {
    gap: 50px;
}


@media only screen and (max-width: 1300px) {
    .contact-wrapper-padding-inline {
        padding-inline: 15vw;
    }
}

@media only screen and (max-width: 1050px) {
    .image-wrapper {
        display: none;
    }

    .position-submit-button-mobile {
        display: flex;
        justify-content: center;
    }

    .contact-wrapper {
        margin-top: 65px;
        gap: 35px;
    }
}

@media only screen and (max-width: 900px) {
    .contact-wrapper-padding-inline {
        padding-inline: 10vw;
    }

    .contact-wrapper {
        margin-top: 60px;
        gap: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .form-elements-gap {
        gap: 20px;
        flex-direction: column;
    }

    .form-gap {
        gap: 20px;
    }

    .contact-info-wrapper {
        flex-direction: column;
        gap: 15px;
    }

    .info-options-wrapper {
        flex-direction: column;
        gap: 10px;
    }
}


