.floor-plans-wrapper {
    overflow: hidden;
    padding-inline: 20vw;
    gap: 20px;
}

.floorplan {
    width: 25vw;
}

#ground-floor-plan {
    aspect-ratio: 632/1770;
}

#first-floor-plan {
    aspect-ratio: 831/1605;
}

#second-floor-plan {
    aspect-ratio: 796/1620;
}

.section-title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #AA9767;
}

.section-title-line-thickness {
    -webkit-text-stroke-width: 2px;
}

.section-title-font-size {
    font-size: 70px;
}

.eye {
    height: 80px;
    aspect-ratio: 1;
    transition: transform 350ms ease-out;
    cursor: pointer;
    z-index: 3;
}

.section-wrapper {
    justify-content: space-between;
}

.section-title-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 1450px) {
    .section-title-font-size {
        font-size: 64px;
    }
}

@media only screen and (max-width: 1300px) {
    .floor-plans-wrapper {
        padding-inline: 15vw;
        gap: 20px;
    }
}

@media only screen and (max-width: 1100px) {
    .section-title-font-size {
        font-size: 60px;
    }
}

@media only screen and (max-width: 950px) {
    .section-title-font-size {
        font-size: 55px;
    }
}

@media only screen and (max-width: 900px) {
    .section-wrapper {
        position: relative;
        justify-content: center;
        width: 100%;
    }

    .floor-plans-wrapper {
        margin-top: 60px;
        padding-inline: 0;
        gap: 30px;
    }

    .section-title-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .section-title-wrapper-width {
        width: 30vw;
    }

    .section-title-mobile {
        display: flex;
        justify-content: center;
        white-space: nowrap;
    }

    #ground-floor {
        transform: rotate(90deg);
    }

    #first-floor {
        transform: rotate(-90deg);
    }

    #second-floor {
        transform: rotate(90deg);
    }

    #section-title-wrapper-1 {
        right: 0;
    }

    #section-title-wrapper-2 {
        left: 0;
    }

    #section-title-wrapper-3 {
        right: 0;
    }

    .floorplan {
        width: 40vw;
    }
}

@media only screen and (max-width: 600px) {
    .floorplan {
        width: 70vw;
    }

    .section-title-wrapper-width {
        width: 15vw;
    }

    .section-title-font-size {
        font-size: 50px;
    }

    .section-title-line-thickness {
        -webkit-text-stroke-width: 1px;
    }
}

@media only screen and (max-width: 500px) {
    .section-title-font-size {
        font-size: 45px;
    }
}


  
